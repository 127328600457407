
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import { useField, useForm } from "vee-validate";
// import * as yup from "yup";
import PromotionCodeService from "@/core/services/PromotionCodeService";
import IPromotionCode from "@/models/IPromotionCode";
import moment from "moment";

interface AddPromoCodeBodyParam {
  id?: any;
  method?: any;
  promotion_code: any;
  code_value: any;
  code_value_type: any;
  expiry_date: any;
  type: any;
  policy_type: any;
  insurance_type: any;
  status: any;
}

export default defineComponent({
  name: "Add-Promotion-Code",
  props: {},

  data() {
    return {};
  },
  setup() {
    let promotionCode = ref<string>("");
    let codeValue = ref<string>("");
    let expiryDate = ref<string>("");
    let codeValueType = ref<string>("");
    let codeStatus = ref<string>("1");
    let type = ref<string>("");
    let policyType = ref<string>("");
    let insuranceType = ref<string>("");

    const rules = ref({
      promotion_code: {
        show: false,
        message: "Please enter pomotion code",
      },
      code_value: {
        show: false,
        message: "Please enter promotion code value",
      },
      expiry_date: {
        show: false,
        message: "Please enter expiry date",
      },
      code_value_type: {
        show: false,
        message: "Please select promotion code value type",
      },
      type: {
        show: false,
        message: "Please select type",
      },
      policy_type: {
        show: false,
        message: "Please select policy type",
      },
      insurance_type: {
        show: false,
        message: "Please select insurance type",
      },
    });

    const store = useStore();
    const route = useRoute();

    let isFormValid = ref<boolean>(true);

    let addPromoCodeBodyParam = {} as AddPromoCodeBodyParam;
    let promotionCodeTypes = ref<string>("0");
    let editPromotionCodeObj = ref<IPromotionCode>();
    addPromoCodeBodyParam = {
      promotion_code: "",
      code_value: "",
      code_value_type: "",
      expiry_date: "",
      type: 1,
      policy_type: "",
      insurance_type: 1,
      status: "1",
    };

    onMounted(() => {
      toFirst()
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Add Promotion Code", ["Promotion Codes"]);

      Promise.all([PromotionCodeService.getPromotionCodeTypes()]).then(
        (data) => {
          promotionCodeTypes.value = store.getters.domPromotionCodeTypes;
        }
      );

      if (route.params.id !== undefined && route.params.id != null) {
        setCurrentPageBreadcrumbs("Edit Promotion Code", ["Promotion Codes"]);
        let params = {
          promotion_code_id: route.params.id,
        };

        Promise.all([PromotionCodeService.showPromotionCode(params)]).then(
          (data) => {
            let editPromotionCodeObj = store.getters.editPromotionCode;

            promotionCode.value = editPromotionCodeObj.promotion_code;
            codeValue.value = editPromotionCodeObj.code_value;
            codeValueType.value = editPromotionCodeObj.code_value_type;
            type.value = editPromotionCodeObj.type;
            policyType.value = editPromotionCodeObj.policy_type;
            insuranceType.value = editPromotionCodeObj.insurance_type;
            expiryDate.value = editPromotionCodeObj.expiry_date;
            codeStatus.value = editPromotionCodeObj.status;

            addPromoCodeBodyParam = {
              promotion_code: editPromotionCodeObj.promotion_code,
              code_value: editPromotionCodeObj.code_value,
              code_value_type: editPromotionCodeObj.code_value_type,
              expiry_date: editPromotionCodeObj.expiry_date,
              type: editPromotionCodeObj.type,
              policy_type: editPromotionCodeObj.policy_type,
              insurance_type: editPromotionCodeObj.insurance_type,
              status: editPromotionCodeObj.status,
            };
          }
        );
      }
    });

    const onChangeStatus = (event) => {
      addPromoCodeBodyParam.status = event.target.value;
    };

    const getExpiryDateTime = (dateTime) => {
      const dateformart = moment(dateTime, 'DD/MM/YYYY', true).isValid() 
      ? moment(dateTime.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD') 
      : dateTime;
      var date = new Date(dateformart);
      addPromoCodeBodyParam.expiry_date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    };

    const validateAddPromoCode = () => {
      if (promotionCode.value == "") {
        rules.value.promotion_code.show = true;
        isFormValid.value = false;
      }
      if (codeValue.value == "") {
        rules.value.code_value.show = true;
        isFormValid.value = false;
      }
      if (expiryDate.value == "") {
        rules.value.expiry_date.show = true;
        isFormValid.value = false;
      }

      if (codeValueType.value == "") {
        rules.value.code_value_type.show = true;
        isFormValid.value = false;
      }
      if (addPromoCodeBodyParam.type == "") {
        rules.value.type.show = true;
        isFormValid.value = false;
      }
      if (policyType.value == "") {
        rules.value.policy_type.show = true;
        isFormValid.value = false;
      }
      if (addPromoCodeBodyParam.insurance_type == "") {
        rules.value.insurance_type.show = true;
        isFormValid.value = false;
      }
    };
    const addPromotionCode = () => {
      isFormValid.value = true;
      
      getExpiryDateTime(expiryDate.value);
      validateAddPromoCode();

      if (isFormValid.value) {
        assignAddPromoCodeParam();

        if (route.params.id !== undefined && route.params.id != null) {
          addPromoCodeBodyParam.id = route.params.id;
          PromotionCodeService.updatePromotionCode(addPromoCodeBodyParam);
        } else {
          PromotionCodeService.addPromotionCode(addPromoCodeBodyParam);
        }
      } else {
        focusField()
      }
    };
    const assignAddPromoCodeParam = () => {
      addPromoCodeBodyParam.promotion_code = promotionCode.value;
      addPromoCodeBodyParam.code_value = codeValue.value;
    };

    const onCodeValueTypeChange = (event) => {
      addPromoCodeBodyParam.code_value_type = event;
      rules.value.code_value_type.show = false
      // codeValueType.value = event.target.value;
    };

    const onTypeChange = (event) => {
      addPromoCodeBodyParam.type = event.target.value;
      type.value = event.target.value;
    };

    const onPolicyTypeChange = (event) => {
      addPromoCodeBodyParam.policy_type = event;
      rules.value.policy_type.show = false
      // policyType.value = event.target.value;
    };

    const onInsuranceTypeChange = (event) => {
      addPromoCodeBodyParam.insurance_type = event.target.value;
      insuranceType.value = event.target.value;
    };

    const disabledDate = (time: Date) => {
      return Date.now() > time.getTime();
    };

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
          const codeRef:any = inst?.refs?.codeRef
          codeRef.focus()
      }, 500);
    }

    const toCode = () => {
      const codeRef:any = inst?.refs?.codeRef
      codeRef.focus()
    }

    const toPromoValue = () => {
      const promoValue:any = inst?.refs?.promoValue
      promoValue.focus()
    }

    const toCodeType = () => {
      const codeType:any = inst?.refs?.codeType
      codeType.focus()
    }

    const toDate = () => {
      const todate:any = inst?.refs?.todate
      todate.isFocus()
    }

    const toPolicyType = () => {
      const policyTypeRef:any = inst?.refs?.policyTypeRef
      policyTypeRef.focus()
    }

    const focusField = () => {
      const { promotion_code, expiry_date, code_value, code_value_type, policy_type } = rules.value

      if(promotion_code.show) {
        toCode()
      }

      if(!promotion_code.show && expiry_date.show) {
        toDate()
      }

      if(!promotion_code.show && !expiry_date.show && code_value.show) {
        toPromoValue()
      }

      if(!promotion_code.show && !expiry_date.show && !code_value.show && code_value_type.show) {
        toCodeType()
      }

      if(!promotion_code.show && !expiry_date.show && !code_value.show && !code_value_type.show && policy_type.show) {
        toPolicyType()
      }
    }

    return {
      toPolicyType,
      focusField,
      toDate,
      toCodeType,
      toCode,
      toPromoValue,
      toFirst,
      addPromoCodeBodyParam,
      addPromotionCode,
      onChangeStatus,
      rules,
      isFormValid,
      promotionCodeTypes,
      disabledDate,
      getExpiryDateTime,
      codeValueType,
      expiryDate,
      promotionCode,
      codeValue,
      codeStatus,
      onCodeValueTypeChange,
      type,
      onTypeChange,
      policyType,
      onPolicyTypeChange,
      insuranceType,
      onInsuranceTypeChange,
      editPromotionCodeObj,
    };
  },
});
